'use client'

import NextLink, { LinkProps } from 'next/link'
import { forwardRef } from 'react'

import type { HTMLProps, PropsWithChildren, Ref } from 'react'
import { useStoreContext } from '@/providers/store/store-context'
import { addSlashToUaHomepagePathname, getUrl } from '@/common/utils/url-utils'
import {
  SkeletonDataType,
  SkeletonTargetType,
  useSkeletonContext,
} from '@/providers/skeleton/skeleton-context'
import { willOpenLinkInNewTab } from '@/common/components/header/components/autocomplete/helpers/autocomplete.utils'

type Props = {
  href: URL | string
  id?: string
  title?: string
  className?: string
  keepUrl?: boolean
  isExternal?: boolean
  skeletonLoader?: {
    target: SkeletonTargetType
    data?: SkeletonDataType
  }
  /**
   * @deprecated don't use "baseUrl"
   */
  baseUrl?: string // TODO: cleanup baseUrl prop drilling
} & LinkProps &
  Omit<HTMLProps<HTMLAnchorElement>, 'href'>

/**
 * - "keepUrl" is set -> `use "href" as is (exception for "ua" homepage)`
 * - "keepUrl" is not set -> `use "baseUrl" to generate origin for "href"`
 * - "isExternal" is set -> `redirection to magento pages - use "a" tag`
 * - "isExternal" is not set -> `in react app navigation - use NextLink`
 *
 * TODO:
 * - cleanup unused utils from "@/common/utils/url-utils"
 * - verify each Link instance used in repo that is correctly called
 * - verify useRouter usage
 */
export const Link = forwardRef(function Link(
  {
    href,
    keepUrl = false,
    isExternal = false,
    skeletonLoader,
    baseUrl: _baseUrl,
    ...props
  }: Props & PropsWithChildren,
  ref: Ref<HTMLAnchorElement> | undefined,
) {
  const {
    storeConfig: { baseUrl },
  } = useStoreContext()
  const url = keepUrl ? href : getUrl(baseUrl, href.toString())
  const urlWithSlashForUaPathname = addSlashToUaHomepagePathname(url).toString()
  const { handleOpenSkeleton } = useSkeletonContext()

  const handleClick = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
  ) => {
    // To prevent being stuck in "loading" state
    if (!willOpenLinkInNewTab(event) && skeletonLoader) {
      history.pushState({}, '', urlWithSlashForUaPathname)
      handleOpenSkeleton(skeletonLoader)
    }

    props.onClick?.(event)
  }

  return isExternal ? (
    <a id="external" href={urlWithSlashForUaPathname} {...props} ref={ref} />
  ) : (
    <NextLink
      data-testid="link"
      prefetch={false}
      href={urlWithSlashForUaPathname}
      {...props}
      onClick={handleClick}
      ref={ref}
    />
  )
})
