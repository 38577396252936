export enum MiddlewareHeader {
  Authorization = 'Authorization',
  CustomerSegment = 'X-Customer-Segment',
  OriginUrl = 'Origin-Url',
  Pathname = 'Pathname',
  Referer = 'Referer',
  RequestUrl = 'RequestUrl',
  Store = 'Store',
  Cookie = 'Cookie',
  CustomerToken = 'customerToken',
  XForwardedUrl = 'X-Forwarded-Url',
  XForwardedFor = 'X-Forwarded-For',
  XRealIp = 'X-Real-IP',
  UserAgent = 'User-Agent',
  Cache = 'x-varnish-status',
  Group = 'X-Next-Customer-Group',
}
