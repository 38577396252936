import React from 'react'

import { formatPrice } from '@/common/utils/price-utils'
import { useStoreContext } from '@/providers'

type Props = {
  productPrice: number | string | undefined
  quantityOrdered?: number | null | undefined
}

const ProductPrice = ({ productPrice, quantityOrdered }: Props) => {
  const {
    storeConfig: { currency, checkoutCurrency },
    storeCode,
  } = useStoreContext()
  const price = (productPrice ? +productPrice : 0) * (quantityOrdered ?? 1)
  const formattedPrice = formatPrice({ storeCode, currency, price })

  const formattedDualPrice =
    checkoutCurrency?.rate && checkoutCurrency?.format
      ? formatPrice({
          storeCode,
          price: (price ?? 0) * (checkoutCurrency.rate ?? 0),
          currencyFormat: checkoutCurrency.format,
          dualCurrency: true,
        })
      : undefined

  return (
    <React.Fragment>
      <span>{formattedPrice}</span>

      {checkoutCurrency?.format && formattedDualPrice && (
        <span className="text-grey-300 ml-1 text-sm font-normal">
          ~({formattedDualPrice})
        </span>
      )}
    </React.Fragment>
  )
}

export default ProductPrice
