'use client'

import { useTranslations } from 'next-intl'

import { Image } from '@/common/components/image/image'
import { ProductLabelV2Fragment, ProductStockStatus } from '@/api'
import { Currency } from '@/components/currency'
import { Rating } from '@/components/rating'
import { ProductName } from '@/components/product-name'
import { AddToCartButton } from '../add-to-cart-button'
import { ProductListItemWrapper } from './product-list-item-wrapper'
import { Thumbnail, PriceRange } from './product-data-item-types'
import { ImageLabelsV2 } from '../image-labels-v2'
import { ListItemProductType } from '@/common/utils'

export type ProductListItemProps = {
  productType?: ListItemProductType
  id?: number | null
  name?: string | null
  productLabels: ProductLabelV2Fragment[]
  thumbnail?: Thumbnail
  priceRange?: PriceRange
  ratingSummary: number
  reviewCount?: number
  stockStatus?: ProductStockStatus //in stock or out of stock. Null value must be is out of stock
  canonicalUrl?: string | null
  onProductClick?: Function
  onAddToCartButtonClick: () => void
  isCartButtonDisabled: boolean
  baseUrl: string
}

export function ProductListItem({
  id,
  name,
  productType,
  productLabels,
  thumbnail,
  priceRange,
  ratingSummary,
  reviewCount,
  stockStatus,
  canonicalUrl,
  onProductClick,
  onAddToCartButtonClick,
  isCartButtonDisabled,
  baseUrl,
}: ProductListItemProps): JSX.Element {
  const t = useTranslations('Product')
  const isGiftCardProduct = productType === ListItemProductType.GiftCard

  return (
    <ProductListItemWrapper
      name={name}
      id={id}
      canonicalUrl={canonicalUrl}
      onProductClick={onProductClick}
      linkDisabled={false}
      baseUrl={baseUrl}
    >
      <div className="relative max-h-[215px] lg:h-[215px] w-fit md:w-auto">
        <Image
          src={thumbnail?.url ?? ''}
          alt={thumbnail?.label ?? 'alternative'}
          priority
          width={500}
          height={500}
          className="h-full lg:h-[215px] max-h-[215px] aspect-square object-contain"
        />
        {stockStatus === ProductStockStatus.InStock ? (
          <AddToCartButton
            disabled={isCartButtonDisabled}
            onClick={(e) => {
              e?.preventDefault()
              e?.stopPropagation()

              onAddToCartButtonClick?.()
            }}
          />
        ) : (
          <span
            title={t('common.status.outOfStock')}
            className={`
              absolute left-0 bottom-[5px]
              flex justify-center items-center
              h-11 w-full
              text-white bg-shadow uppercase font-bold
              `}
          >
            {t('common.status.outOfStock')}
          </span>
        )}

        {!!productLabels?.length && <ImageLabelsV2 labels={productLabels} />}
      </div>
      <ProductName>
        <span className="line-clamp-2">{name}</span>
      </ProductName>
      <Rating
        value={ratingSummary}
        voteCount={reviewCount}
        className="ml-[-5px]"
        voteCountClassName="font-bold text-xs m-0"
      />
      <Currency
        className="text-sm font-bold text-secondary mt-1 h-[1em] block"
        price={priceRange?.price}
        isPriceRange={priceRange?.isPriceRange || isGiftCardProduct}
      />
    </ProductListItemWrapper>
  )
}
