'use client'

import { useMemo, useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import Slider from 'react-slick'

import { SKU_RECENTLY_VIEWED_PRODUCT } from '@/providers/product-data/utils'
import { selectors } from '@/common/constants/selectors-constants'
import { fetchTopProducts } from '../../homepage-api'
import { ProductListItemSkeleton } from '@/components/product-list-item/product-list-item-skeleton'
import { ProductListItem } from '@/components/product-list-item/product-list-item'
import {
  PurchaseDialog,
  PurchaseDialogProduct,
} from '@/components/purchase-dialog/purchase-dialog'
import { useCartContext, useStoreContext } from '@/providers'
import { ProductType } from '@/common/types'
import { useDisclosure } from '@/common/hooks/use-disclosure'
import {
  AddToCartEventHandlerParams,
  SimpleVariantAddToCartParams,
} from '@/providers/cart/cart.types'
import {
  addConfigProductToCart,
  addSimpleProductToCart,
} from '@/components/purchase-dialog/purchase-dialog.utils'
import { getSliderSettings } from './slider-config'

export type HomepageProductsGridProps = {
  baseUrl: string
}

export function HomepageProductsGrid({
  baseUrl,
}: HomepageProductsGridProps): JSX.Element {
  const { storeCode } = useStoreContext()
  const { isOpen, onClose, onOpen } = useDisclosure()
  const { addToCart, isCartRefetching, isAddingToCart } = useCartContext()

  const [dialogProductData, setDialogProductData] = useState<
    PurchaseDialogProduct | undefined
  >()
  const [activeSlide, setActiveSlide] = useState<number>(0)

  const { data: topProducts, isLoading } = useQuery({
    queryKey: ['topProducts', storeCode],
    queryFn: async () => {
      let lastViewedProductsSkus = []
      try {
        lastViewedProductsSkus = JSON.parse(
          localStorage.getItem(SKU_RECENTLY_VIEWED_PRODUCT) ?? '[]',
        )
      } catch {}
      return fetchTopProducts(lastViewedProductsSkus, storeCode)
    },
  })

  const handleConfigOnAddToCartEvent = async ({
    variant,
    ...rest
  }: AddToCartEventHandlerParams) => {
    const trackingOptions = {
      configId: Number(dialogProductData?.id ?? 0),
      simpleId: Number(variant?.product?.id ?? 0),
    }

    addConfigProductToCart({ variant, ...rest }, trackingOptions)
  }

  const handleSimpleOnAddToCartEvent = async (
    params: SimpleVariantAddToCartParams,
  ) => {
    addSimpleProductToCart(params, addToCart)
  }

  const isCartButtonDisabled = !addToCart || isCartRefetching || isAddingToCart
  const settings = useMemo(
    () => getSliderSettings(activeSlide, setActiveSlide),
    [activeSlide],
  )

  return (
    <>
      {dialogProductData && (
        <PurchaseDialog
          productListData={dialogProductData}
          onClose={() => {
            onClose()
            setDialogProductData(undefined)
          }}
          onAddToCartEvent={handleConfigOnAddToCartEvent}
          isOpen={isOpen}
        />
      )}

      <div className="hidden md:block lg:hidden">
        {topProducts ? (
          <Slider {...settings}>
            {topProducts.map((product) => (
              <div
                key={`product_hp_grid_${product.id}`}
                className="min-h-[330px]"
              >
                <ProductListItem
                  key={product.id}
                  productType={product.type}
                  isCartButtonDisabled={isCartButtonDisabled}
                  onAddToCartButtonClick={() => {
                    if (!product.isProductSingleVariant) {
                      setDialogProductData({
                        ...product,
                        productType: product.type,
                        parentSku: product.sku,
                      })
                      onOpen()
                    } else {
                      handleSimpleOnAddToCartEvent({
                        productType: ProductType.Configurable,
                        brand: product.manufacturerInfo.name,
                        category: product.breadcrumbEn,
                        parentSku: product.sku ?? undefined,
                        price: product.finalPrice,
                        productName: product.name,
                        sku: product.singleVariantSku,
                        configId: Number(product.id),
                        simpleId: Number(
                          product.configurableVariants.find(
                            (variant) =>
                              variant.product.sku === product.singleVariantSku,
                          )?.product.id,
                        ),
                      })
                    }
                  }}
                  baseUrl={baseUrl ?? ''}
                  {...product}
                  id={Number(product.id)}
                />
              </div>
            ))}
          </Slider>
        ) : (
          <div className="flex justify-between gap-4">
            {Array.from({ length: 4 }).map((_, index) => (
              <ProductListItemSkeleton key={index} />
            ))}
          </div>
        )}
      </div>

      <div
        className="w-full grid grid-cols-2 md:hidden lg:grid lg:grid-cols-6 gap-6 my-5"
        data-test={selectors.HP.bestsellers}
      >
        {!isLoading && topProducts ? (
          topProducts.map((product) => {
            return (
              <ProductListItem
                key={product.id}
                productType={product.type}
                isCartButtonDisabled={isCartButtonDisabled}
                onAddToCartButtonClick={() => {
                  if (!product.isProductSingleVariant) {
                    setDialogProductData({
                      ...product,
                      productType: product.type,
                      parentSku: product.sku,
                    })
                    onOpen()
                  } else {
                    handleSimpleOnAddToCartEvent({
                      productType: ProductType.Configurable,
                      brand: product.manufacturerInfo.name,
                      category: product.breadcrumbEn,
                      parentSku: product.sku ?? undefined,
                      price: product.finalPrice,
                      productName: product.name,
                      sku: product.singleVariantSku,
                      configId: Number(product.id),
                      simpleId: Number(
                        product.configurableVariants.find(
                          (variant) =>
                            variant.product.sku === product.singleVariantSku,
                        )?.product.id,
                      ),
                    })
                  }
                }}
                baseUrl={baseUrl ?? ''}
                {...product}
                id={Number(product.id)}
              />
            )
          })
        ) : (
          <>
            {Array.from({ length: 12 }).map((_, index) => (
              <ProductListItemSkeleton key={index} />
            ))}
          </>
        )}
      </div>
    </>
  )
}
