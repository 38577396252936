'use client'

import { PropsWithChildren } from 'react'

import { gtmTracker } from '@/common/services/tracking/google/gtm-tracker'
import { Link } from '../link/link'

export type ProductListItemWrapperProps = PropsWithChildren<{
  name?: string | null
  id?: number | string | null
  canonicalUrl?: string | null
  onProductClick?: Function
  linkDisabled: boolean | null
  baseUrl: string
}>

export function ProductListItemWrapper({
  name,
  id,
  canonicalUrl,
  onProductClick,
  linkDisabled,
  children,
  baseUrl,
}: ProductListItemWrapperProps): JSX.Element {
  return name ? (
    <Link
      skeletonLoader={{
        target: 'product',
        data: { productName: name },
      }}
      title={!linkDisabled ? name : undefined}
      aria-label={`Link to the ${name}`}
      baseUrl={baseUrl}
      href={canonicalUrl ?? ''}
      id={`product_item_${id}`}
      onClick={(e) => {
        linkDisabled
          ? e.preventDefault()
          : gtmTracker.trackRecommendedProductClick()
        onProductClick?.()
      }}
      className={`${
        linkDisabled ? 'cursor-default' : '[&:hover_.product-name]:underline'
      }
         max-h-[330px] sm:h-[330px]`}
    >
      {children}
    </Link>
  ) : (
    <div className="max-h-[330px] sm:h-[330px]">{children}</div>
  )
}
