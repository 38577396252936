import { HTMLAttributes, PropsWithChildren } from 'react'

interface ContainerProps extends HTMLAttributes<HTMLDivElement> {}

export function Container({
  children,
  ...props
}: PropsWithChildren<ContainerProps>): JSX.Element {
  return (
    <div
      className="container w-full max-w-screen-desktop mx-auto px-5 2xl:px-0"
      {...props}
    >
      {children}
    </div>
  )
}
