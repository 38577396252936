import { notFound } from 'next/navigation'

import { GraphQlErrorsResponse } from '../api/graphql'

export function handleServerErrors<T extends {}>(
  data?: T & GraphQlErrorsResponse,
): void {
  if (Object.keys(data ?? {}).length === 0) {
    notFound()
  }

  if (data?.errors && data?.errors.length > 0) {
    throw new Error(`Internal Error: ${data.errors[0]}`, { cause: { data } })
  }
}
