'use client'

import { createContext, useContext } from 'react'

import type { PropsWithChildren } from 'react'
import { StoreCodeType, StoreConfigType } from '@/common/types'
import { FALLBACK_STORE_CODE } from '@/common/utils'

export interface StoreContextType {
  storeCode: StoreCodeType
  storeConfig: StoreConfigType
}

export const StoreContext = createContext<StoreContextType>({
  storeCode: FALLBACK_STORE_CODE,
  storeConfig: {
    baseUrl: '',
  },
} as StoreContextType)

interface DataContextProviderProps {
  storeCode: StoreCodeType
  storeConfig: StoreConfigType
}

export const StoreContextProvider = ({
  children,
  storeCode,
  storeConfig,
}: PropsWithChildren<DataContextProviderProps>) => {
  return (
    <StoreContext.Provider value={{ storeCode, storeConfig }}>
      {children}
    </StoreContext.Provider>
  )
}

export const useStoreContext = () => useContext(StoreContext)
