import { StoreConfigQuery } from '@/api'
import { Domain, StoreCodeType, StoreConfigType } from '@/common/types'
import { Currency } from '../types/price-types'

// TODO: remove workaround when expected results come from API
export function getBaseUrl(storeCode: StoreCodeType, domain: Domain): string {
  let url = process.env.NEXT_PUBLIC_BASE ?? ''

  // RU has the same domain name as UA...
  // TODO: change RU domain to UA
  if (storeCode === Domain.RU) {
    url = url.replace('<domain>', Domain.UA)
  } else {
    url = url.replace('<domain>', domain)
  }

  // handle branch env
  if (process.env.NEXT_PUBLIC_BRANCH_NAME) {
    url = url.replace('<branch-name>', process.env.NEXT_PUBLIC_BRANCH_NAME)
  }

  // Exception only for UA nad RU stores
  // TODO: convert check to UA domain only and use locale to differentiate (dependency: change RU domain to UA)
  if (storeCode === Domain.UA || storeCode === Domain.RU) {
    url = `${url}/${domain}`
  }

  return url
}

export const processStoreConfig = (
  storeConfigQuery: StoreConfigQuery,
  storeCode: StoreCodeType,
  domain: Domain,
): StoreConfigType => {
  const {
    checkout_currency: checkoutCurrency,
    cookie_restriction: cookieRestriction,
    default_display_currency_code: currency,
    default_display_currency_symbol: currencySymbol,
    date_format: dateFormat,
    locale,
    website_switcher: websiteSwitcher,
    head_includes: headIncludes,
    head_shortcut_icon: headShortcutIcon,
    default_description: defaultDescription,
    base_url: baseUrl,

    // widgets
    dognet_s2s: dognetS2s,
  } = storeConfigQuery.storeConfig ?? {}

  return {
    baseUrl: getBaseUrl(storeCode, domain) ?? baseUrl ?? '',
    cookieRestriction: cookieRestriction ?? false,
    currency: (currency as Currency) ?? undefined,
    currencySymbol: currencySymbol ?? undefined,
    dateFormat: dateFormat ?? undefined,
    locale: locale ?? undefined,

    checkoutCurrency: checkoutCurrency
      ? { rate: checkoutCurrency.rate, format: checkoutCurrency.format }
      : undefined,

    websiteSwitcher: websiteSwitcher,
    headIncludes: headIncludes ?? undefined,
    headShortcutIcon: headShortcutIcon ?? undefined,
    defaultDescription: defaultDescription ?? undefined,

    dognetS2s: dognetS2s
      ? {
          enabled: dognetS2s?.enabled ?? undefined,
          accountId: dognetS2s?.account_id ?? undefined,
          cookieConsent: dognetS2s?.cookie_consent ?? undefined,
        }
      : undefined,

    facebookAppId: storeConfigQuery.storeConfig?.facebook_app_id ?? undefined,
  }
}
