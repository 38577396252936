import {
  ArrowCircleLeftIcon,
  ArrowCircleRightIcon,
} from '@/components/icons/svg'
import { cn } from '@/common/utils/lib'

export type ProductsSliderArrowProps = {
  onClick?: () => void
  style?: React.CSSProperties
  activeSlide: number | null
  amountOfSlides?: number | null
}

const START_SLIDE = 0

export function ProductsSliderNextArrow({
  style,
  onClick,
  activeSlide,
  amountOfSlides = 8,
}: ProductsSliderArrowProps): JSX.Element {
  return (
    <ArrowCircleRightIcon
      width="2.5rem"
      height="2.5rem"
      className={cn(
        'absolute right-0 top-1/3 cursor-pointer hidden sm:block z-10',
        activeSlide === amountOfSlides ? 'cursor-not-allowed' : '',
      )}
      style={activeSlide === amountOfSlides ? { opacity: 0.5 } : style}
      onClick={onClick}
    />
  )
}

export function ProductsSliderPreviousArrow({
  style,
  onClick,
  activeSlide,
}: ProductsSliderArrowProps): JSX.Element {
  return (
    <ArrowCircleLeftIcon
      width="2.5rem"
      height="2.5rem"
      className={cn(
        'absolute left-0 top-1/3 cursor-pointer hidden sm:block z-10',
        activeSlide === START_SLIDE ? 'cursor-not-allowed' : '',
      )}
      style={activeSlide === START_SLIDE ? { opacity: 0.5 } : style}
      onClick={onClick}
    />
  )
}
