'use client'

import { PropsWithChildren, useState } from 'react'
import { QueryClientProvider, HydrationBoundary } from '@tanstack/react-query'

import { queryClient as client } from '@/services'
import { StoreCodeType, StoreConfigType } from '@/common/types'
import { AuthContextProvider } from './auth'
import { CartContextProvider } from './cart'
import { StoreContextProvider } from './store'
import { ReCaptchaProvider } from './re-captcha'
import { FlashMessagesProvider } from './flash-messages'
import { FullScreenSpinnerProvider } from './full-screen-spinner'
import { SkeletonContextProvider } from './skeleton/skeleton-context'

interface ProvidersProps {
  storeCode: StoreCodeType
  storeConfig: StoreConfigType
}

//page data context
export function Providers({
  children,
  storeCode,
  storeConfig,
}: PropsWithChildren<ProvidersProps>) {
  const [queryClient] = useState(() => client)

  return (
    <FlashMessagesProvider>
      <QueryClientProvider client={queryClient}>
        <HydrationBoundary>
          <ReCaptchaProvider>
            <SkeletonContextProvider>
              <FullScreenSpinnerProvider>
                <AuthContextProvider storeCode={storeCode}>
                  <CartContextProvider
                    storeCode={storeCode}
                    storeConfig={storeConfig}
                  >
                    <StoreContextProvider
                      storeCode={storeCode}
                      storeConfig={storeConfig}
                    >
                      {children}
                    </StoreContextProvider>
                  </CartContextProvider>
                </AuthContextProvider>
              </FullScreenSpinnerProvider>
            </SkeletonContextProvider>
          </ReCaptchaProvider>
        </HydrationBoundary>
      </QueryClientProvider>
    </FlashMessagesProvider>
  )
}
