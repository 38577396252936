import {
  CategoryMetadataFragment,
  CategoryPageProductsQuery,
  CategorySearchFiltersQuery,
} from '@/api'

export type CategorySearchFilters = Extract<
  CategorySearchFiltersQuery['route'],
  { __typename: 'CategoryTree' }
>

export type CategorySearchProductGQL = NonNullable<
  NonNullable<
    NonNullable<
      Extract<
        CategoryPageProductsQuery['route'],
        { __typename: 'CategoryTree' }
      >['categorySearch']
    >['items']
  >[0]
>

export type CategoryMetadata = CategoryMetadataFragment
export type AvailableCategorySearchFilters = NonNullable<
  NonNullable<CategorySearchFilters>['categorySearch']
>['availableFilters']

export type CategoryFilterOption = {
  used?: boolean | null
  count?: number | null
  url?: string | null
  name?: string | null
}

export type CategoryFilter = {
  code?: string | null
  name?: string | null
  type?: string | null
  options?: Array<CategoryFilterOption | null> | null
}

export type CategoryFiltersCheckbox = {
  code?: string | null
  name?: string | null
  count?: number | null
  url?: string | null
  used?: boolean | null
}

export const FILTER_EXPANDED_PARAM = 'filters-expanded'
