import { MiddlewareHeader } from '@/common/types/header-types'
import { consoleError } from '@/common/utils/console'

export enum HttpMethod {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
}

export type RestHeaders = {
  storeCode?: string
  token?: string
  xApiKey?: string
  [key: string]: string | undefined
}

type Error = {
  message: string
}

export type GraphqlPayload<T> = {
  query: string
  variables?: T
}

export const restFetch = async <RESPONSE, PROPS = void>(
  url: string,
  method: HttpMethod = HttpMethod.GET,
  headers: RestHeaders = {},
  data?: PROPS,
  next?: NextFetchRequestConfig,
  isCache: boolean = false,
): Promise<any> => {
  let response: any
  const { storeCode, token, xApiKey, ...restHeaders } = headers

  try {
    response = await fetch(url, {
      method,
      headers: {
        'Content-Type': 'application/json',
        ...(storeCode ? { [MiddlewareHeader.Store]: storeCode } : {}),
        ...(xApiKey ? { 'x-api-key': xApiKey } : {}),
        ...(token
          ? { [MiddlewareHeader.Authorization]: `Bearer ${token}` }
          : {}),
        ...restHeaders,
      },
      body: data ? JSON.stringify(data) : undefined,
      next,
      ...(!isCache && { cache: 'no-store' }),
    })

    if (!response.ok) {
      const error = (await response.json()) as Error
      return { error }
    }

    const responseData = (await response.json()) as RESPONSE
    return { data: responseData }
  } catch (error) {
    consoleError('rest-fetcher - fetch', {
      status: response.status,
      url,
      headers,
    })
    throw new Error(`Network error: ${(error as Error).message}`)
  }
}
